import Chart from "chart.js/auto";
import { ChartConfiguration } from "chart.js";
import { useLayoutEffect, useMemo, useRef } from "react";
import { angularize } from "react-in-angularjs";
import { drawAverageChartjsPlugin } from "./_drawHistogramAverage.plugin";

type ChartColorType = "backgroundColor" | "borderColor" | "color";

interface ChartJsProps {
  data: ChartConfiguration & {
    colors: Record<ChartColorType, string>;
  };
  average: {
    standardDeviation: number;
    average: number;
  };
  width: string;
  maxWidth: string;
  height: string;
  maxHeight: string;
}

(() => {
  // default chart defaults

  const getColorByCSSVariableName = (variableName: string) => {
    const propertyValue = getComputedStyle(
      document.documentElement
    ).getPropertyValue(variableName);
    if (propertyValue && propertyValue !== "") {
      return propertyValue;
    }

    return;
  };

  const getChartColorPrimary = (fallback: any) =>
    getColorByCSSVariableName("--chartColorPrimary") || fallback;

  const getChartColorPrimaryTransparent = (fallback: any) =>
    getColorByCSSVariableName("--chartColorPrimaryTransparent") || fallback;

  // set default config
  Chart.defaults.backgroundColor = getChartColorPrimary(
    Chart.defaults.backgroundColor
  );

  // set line config
  const { backgroundColor, borderColor } = Chart.defaults.elements.line;
  Chart.defaults.elements.line = {
    ...Chart.defaults.elements.line,
    backgroundColor: getChartColorPrimaryTransparent(backgroundColor),
    borderColor: getChartColorPrimary(borderColor),
    borderWidth: 2,
    fill: "origin",
    tension: 0.4
  };
})();

const ChartJs = ({
  data: chartData,
  average: chartAverage,
  width,
  height,
  maxWidth,
  maxHeight
}: ChartJsProps) => {
  const chartRef = useRef<HTMLCanvasElement>(null);

  const aspectRatio = useMemo(
    () => parseInt(width) / parseInt(height),
    [width, height]
  );

  useLayoutEffect(() => {
    const { current: chartElement } = chartRef;
    if (!chartElement) return;

    chartData.options = {
      ...chartData.options,
      plugins: {
        ...chartData.options?.plugins,
        legend: {
          display: false
        }
      },
      responsive: true,
      maintainAspectRatio: true,
      aspectRatio
    };

    if (chartAverage) {
      chartData.plugins = [drawAverageChartjsPlugin];
      if (!chartData.options.plugins) {
        chartData.options.plugins = {};
      }

      chartData.options.plugins.draw_average = chartAverage;
    }

    new Chart(chartElement, chartData);
  }, []);

  return (
    <div
      style={{
        aspectRatio,
        width: "100%",
        maxHeight,
        maxWidth,
        position: "relative"
      }}
    >
      <canvas ref={chartRef}></canvas>
    </div>
  );
};

export default ChartJs;

angularize(ChartJs, "chartJs", angular.module("diceApp"), {
  data: "<",
  height: "<?",
  average: "<?",
  maxHeight: "<?",
  maxWidth: "<?",
  width: "<?"
});
