import type { Chart } from "chart.js";

const getPixelForValueFromX = (
  chart: Chart,
  raw: number
): number | undefined => {
  const axis = chart.scales.x;
  if (!axis) return;

  const values = chart.data.labels as number[];

  let value = Math.max(raw, Math.min(...values));
  value = Math.min(value, Math.max(...values));

  const index = values.findIndex((currValue) => value === currValue);

  // xAxis pixel value is calculate from the center of the bar since v4
  // We first calculate the total width of a bar,
  // and subtract half to know the distance to the start of the grid.
  const xAxisWidth = axis.getPixelForValue(1) - axis.getPixelForValue(0);
  const xAxisStartDistance = xAxisWidth / 2;

  if (index !== -1) {
    return axis.getPixelForTick(index) - xAxisStartDistance;
  } else if (value !== Math.floor(value)) {
    const startPixelValue = getPixelForValueFromX(chart, Math.floor(value));
    const endPixelValue = getPixelForValueFromX(chart, Math.ceil(value));

    if (startPixelValue === undefined || endPixelValue === undefined) return;

    const totalDistance = endPixelValue - startPixelValue;
    const distance = totalDistance * (value - Math.floor(value));

    return startPixelValue + distance;
  }

  return;
};

interface DrawCTXProps {
  ctx: CanvasRenderingContext2D;
  x?: number;
  y?: number;
  color: string;
}

interface DrawLineCTXProps extends DrawCTXProps {
  startY?: number;
  startX?: number;
  endX?: number;
  endY?: number;
  lineWidth: number;
}

interface DrawTextCTXProps extends DrawCTXProps {
  text: string;
  align: CanvasTextAlign;
}

const drawText = ({
  ctx,
  text,
  align,
  color,
  x = 0,
  y = 0
}: DrawTextCTXProps) => {
  ctx.textAlign = align;
  ctx.fillStyle = color;
  ctx.fillText(text, x, y);
};

const drawVerticalLine = ({
  ctx,
  x = 0,
  startY = 0,
  endY = 0,
  color,
  lineWidth
}: DrawLineCTXProps) => {
  ctx.beginPath();
  ctx.moveTo(x, startY);
  ctx.strokeStyle = color;
  ctx.lineWidth = lineWidth;
  ctx.lineTo(x, endY);
  ctx.stroke();
};

const drawHorizontalLine = ({
  ctx,
  startX = 0,
  endX = 0,
  y = 0,
  color,
  lineWidth
}: DrawLineCTXProps) => {
  ctx.strokeStyle = color;
  ctx.lineWidth = lineWidth;
  ctx.beginPath();
  ctx.moveTo(startX, y);
  ctx.lineTo(endX, y);
  ctx.stroke();
};

const drawAverage = (
  chart: Chart,
  _args: any,
  { standardDeviation, average }: { standardDeviation: number; average: number }
) => {
  const { ctx } = chart;
  const yAxis = chart.scales.y;

  if (standardDeviation && standardDeviation > 0) {
    const startDeviation = average - standardDeviation;
    const endDeviation = average + standardDeviation;

    const xValues = chart.data.labels as number[];
    // check if deviation can get drawn, maximum deviation from start and end is 0.5
    if (
      startDeviation >= Math.min(...xValues) - 0.5 &&
      endDeviation <= Math.max(...xValues) + 0.5
    ) {
      const startPixelDeviation = getPixelForValueFromX(chart, startDeviation);
      const endPixelDeviation = getPixelForValueFromX(chart, endDeviation);

      drawVerticalLine({
        ctx,
        x: startPixelDeviation,
        startY: yAxis.top + 37.5,
        endY: yAxis.top + 52.5,
        color: "#000000",
        lineWidth: 2
      });
      drawHorizontalLine({
        ctx,
        startX: startPixelDeviation,
        endX: endPixelDeviation,
        y: yAxis.top + 45,
        color: "#000000",
        lineWidth: 1
      });
      drawVerticalLine({
        ctx,
        x: endPixelDeviation,
        startY: yAxis.top + 37.5,
        endY: yAxis.top + 52.5,
        color: "#000000",
        lineWidth: 2
      });
    }
  }

  const pixelForValue = getPixelForValueFromX(chart, average);

  ctx.save();
  drawVerticalLine({
    ctx,
    x: pixelForValue,
    startY: yAxis.top + 32.5,
    endY: yAxis.top + 57.5,
    color: "#ff0000",
    lineWidth: 5
  });

  drawText({
    ctx,
    text: "avg",
    align: "center",
    color: "#ff0000",
    x: pixelForValue,
    y: yAxis.top + 20
  });

  ctx.restore();
};

export const drawAverageChartjsPlugin = {
  id: "draw_average",
  afterDraw: drawAverage
};
