import { angularize } from "react-in-angularjs";
import QRCode from "react-qr-code";

interface GenerateQRCodeProps {
  url: string;
  size: number;
}

const GenerateQRCode = ({ url, size = 200 }: GenerateQRCodeProps) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center"
      }}
    >
      <QRCode value={url} size={size} />
    </div>
  );
};

angularize(GenerateQRCode, "generateQrCode", angular.module("diceApp"), {
  url: "@",
  size: "<?"
});

export default GenerateQRCode;
